import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import { API_PATH } from "../../../../const/api_paths";
import { useReferences } from "../../../context/references";

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(2),
  },
}));

export default ({ account }) => {
  const classes = useStyles();
  const { myself } = useReferences();

  const generateGridReport = () => {
    const encoded = window.btoa(`${window.crypto.randomUUID()}--${myself.id}`);
    const target_url = API_PATH(
      `/reports/account_grid.csv?account_id=${account.id}&uid=${encoded}`
    );
    window.location = target_url;
  };

  return (
    <Box className={classes.box}>
      <Button
        ssize="small"
        variant="outlined"
        endIcon={<SaveAltIcon />}
        onClick={generateGridReport}
      >
        ATT Grid Report
      </Button>
    </Box>
  );
};
