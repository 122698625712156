import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Name from "./_add_task/Name";
import AccountLocation from "./_add_task/AccountLocation";
import DueDate from "./_add_task/DueDate";
import TaskCategory from "./_add_task/TaskCategory";
import Tags from "./_add_task/Tags";
import Description from "./_add_task/Description";
import SopAccountingTaskTemplate from "./_add_task/AccountingTaskTemplate";
import SopFileUpload from "../../../shared/components/upload/TaskSop";
import { doAuthenticatedPostFormData } from "../../../actions/_methods";
import { API_TASK_REQUESTS } from "../../../const/api_paths";
import { useSnackbar } from "notistack";
import { useFirmTasks } from "../../context/firm_tasks";
import { Box, Card, CardHeader, CardContent, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  inner_box: {
    display: "flex",
    marginTop: "25px",
  },
  buttons_box: {
    marginTop: "25px",
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel-btn": {
      marginLeft: "15px",
    },
  },
}));

export default ({
  onCloseRequestTask,
  onAddData,
  group_by,
  group_id,
  filters,
}) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [isSopUpload, setIsSopUpload] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const { preferences } = useFirmTasks();
  const allowAddName = preferences.firm_v2 === "false";

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    clearData();
  }, []);

  const clearData = () => {
    setData({});
    setData({ firm_id: filters.firm_id });
  };

  const doRequestTask = (params) => {
    var formData = new FormData();
    for (var key in params) {
      const value = params[key];
      // If value is an array, stringify it first since we are using FormData
      if (Array.isArray(value) && value[0] instanceof File) {
        value.forEach((file, index) => {
          formData.append(`${key}[${index}]`, file);
        });
      } else if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }

    doAuthenticatedPostFormData(API_TASK_REQUESTS, formData, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
      catch: () => {
        setDisableSaveButton(false);
      },
    });
  };

  const onClickRequestTask = () => {
    let error = validateTaskRequest();
    if (error.length > 0) {
      enqueueSnackbar(error.join("\n"), { variant: "error" });
    } else {
      setDisableSaveButton(true);
      doRequestTask(data);
    }
  };

  const validateTaskRequest = () => {
    let error = [];
    if (
      data["task_category_id"] == undefined ||
      data["task_category_id"] == ""
    ) {
      error.push("Sub Category is required.");
    }

    return error;
  };

  const onChange = (new_data) => {
    const params = { ...data, ...new_data };
    setData(params);
    if (["SOP Update", "SOP New"].includes(params["task_category_name"]))
      setIsSopUpload(true);
    else setIsSopUpload(false);
  };

  const onSuccess = (res) => {
    setDisableSaveButton(false);
    clearData();
    enqueueSnackbar("You have successfully requested a task.", {
      variant: "success",
    });
    onCloseRequestTask();
  };

  const onError = (res) => {
    setDisableSaveButton(false);
    let errorMessage = "Unable to request a task:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" - ");
    }
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
  };

  const afterSopFileUpload = (file) => {
    // Append file to sop_update_files array
    const sop_update_files = data.sop_update_files || [];
    sop_update_files.push(file);
    setData({ ...data, sop_update_files: sop_update_files });
  };

  const afterSopFileRemove = (file) => {
    // Remove file to sop_update_files array
    const sop_update_files = data.sop_update_files || [];
    sop_update_files.splice(sop_update_files.indexOf(file), 1);
    setData({ ...data, sop_update_files: sop_update_files });
  };

  const SaveTaskRequestButton = () => (
    <Button
      variant="contained"
      color="primary"
      disabled={disableSaveButton}
      onClick={onClickRequestTask}
    >
      Request Task
    </Button>
  );

  const CancelButton = () => (
    <Button
      variant="contained"
      className="cancel-btn"
      onClick={onCloseRequestTask}
    >
      Cancel
    </Button>
  );

  return (
    <Card className={classes.box} id="request_task_section">
      <CardHeader title="Request a new Task" />
      <CardContent>
        {allowAddName && (
          <Box>
            <Name onChange={onChange} />
          </Box>
        )}
        <Box className={classes.inner_box}>
          <AccountLocation
            onChange={onChange}
            firm_id={filters.firm_id}
            {...{ group_by, group_id }}
          />
        </Box>
        <Box className={classes.inner_box}>
          <DueDate onChange={onChange} />
          <TaskCategory onChange={onChange} required={true} />
        </Box>
        {isSopUpload && (
          <Box className={classes.inner_box}>
            <SopAccountingTaskTemplate data={data} onChange={onChange} />
          </Box>
        )}
        <Box className={classes.inner_box}>
          <Tags
            onChange={onChange}
            taskCategoryName={data.task_category_name}
          />
        </Box>
        <Box className={classes.inner_box}>
          <Description onChange={onChange} />
        </Box>
        {isSopUpload && (
          <Box className={classes.inner_box}>
            <SopFileUpload
              afterUpload={afterSopFileUpload}
              afterRemove={afterSopFileRemove}
            />
          </Box>
        )}
        <Box className={classes.buttons_box}>
          <SaveTaskRequestButton />
          <CancelButton />
        </Box>
        <Box>{data.length}</Box>
      </CardContent>
    </Card>
  );
};
