import React from "react";

import { makeStyles } from "@mui/styles";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";

import { SHIFT_OPTIONS } from "../../../../features/board/const/ref";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    marginLeft: 100,
    marginTop: 20,
    marginRight: 10,
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
  },
  formControl: {
    width: 260,
  },
});

const EditShift = ({ data, onChange }) => {
  const classes = useStyles();
  const [shift, setShift] = React.useState(data.shift);

  const handleChange = (event) => {
    setShift(event.target.value);
    onChange({ shift: event.target.value });
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.header}>
        Shift (1 is US, 3 is PH, etc.)
      </Typography>
      <FormControl className={classes.formControl}>
        <Select size="small" value={shift} onChange={handleChange}>
          {SHIFT_OPTIONS.map((shift) => (
            <MenuItem key={shift.value} value={shift.value}>
              {shift.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default EditShift;
