import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListRow from "./listRow";
import Loader from "./components/Loader";
import CallToActionAlert from '../../shared/components/CallToActionAlert';

import { useGlobal } from "../context/global";
import { usePermission } from "../context/permission";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    marginBottom: "12px",
    padding: "12px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default () => {
  const classes = useStyles();
  const { setPageKnowledgeBaseTags } = useGlobal();
  const superAngelUser = usePermission()?.isSuperAngel() ?? false;
  const myReports = usePermission()?.reports ?? [];

  useEffect(() => {}, [myReports]);

  useEffect(() => {
    setPageKnowledgeBaseTags(["Reports"]);
  }, []);

  const findReportByName = (name) => {
    return _.find(myReports, { name: name });
  };

  if (myReports.length == 0) {
    return <Loader height={300} />;
  } else {
    return (
      <>
        <CallToActionAlert />
        <Card elevation={3} className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Reports
            </Typography>
            <List className={classes.container}>
              <ListRow
                path="/app/reports/weekly_summary"
                name="Weekly Summary"
                report={findReportByName("Weekly Summary")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/user_weekly_summary"
                name="User Weekly Summary"
                report={findReportByName("User Weekly Summary")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/user_data"
                name="User Weekly Summary v2"
                report={findReportByName("User Weekly Summary v2")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/comparative"
                name="Month/Quarter Comparative Report"
                report={findReportByName("Month/Quarter Comparative Report")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/task_weekly_count"
                name="Task Weekly Count"
                report={findReportByName("Task Weekly Count")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/daily_task_reports"
                name="Daily Task Reports"
                report={findReportByName("Daily Task Reports")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/weekly_task_reports"
                name="Weekly Task Reports"
                report={findReportByName("Weekly Task Reports")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/weekly_firm_reports"
                name="Weekly Firm Reports"
                report={findReportByName("Weekly Firm Reports")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/firm_tasks_reports"
                name="Firm Tasks Reports"
                report={findReportByName("Firm Tasks Reports")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/account_te_tv_reports"
                name="Account Time Estimate and Time Variance Reports"
                report={findReportByName(
                  "Account Time Estimate and Time Variance Reports"
                )}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/data_report_weekly"
                name="Data Report - Weekly"
                report={findReportByName("Data Report - Weekly")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/data_report_monthly"
                name="Data Report - Monthly"
                report={findReportByName("Data Report - Monthly")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/time_variance"
                name="Time Variance Report"
                report={findReportByName("Time Variance Report")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/scope_variance"
                name="Scope Variance Report"
                report={findReportByName("Scope Variance Report")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/accounting_time_variance"
                name="Accounting Time Variance Report"
                report={findReportByName("Accounting Time Variance Report")}
                superAngelUser={superAngelUser}
              />
              <ListRow
                path="/app/reports/task_requests"
                name="Task Requests"
                report={findReportByName("Task Requests")}
                superAngelUser={superAngelUser}
              />
            </List>
          </CardContent>
        </Card>
      </>
    );
  }
};
