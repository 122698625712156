import React from 'react';
import { Router } from '@reach/router';
import { GlobalCtxProvider } from '../features/context/global';
import { SnackbarProvider } from 'notistack';
import { FirmTasksCtxProvider } from '../features/context/firm_tasks';
import { PermissionCtxProvider } from '../features/context/permission';
import { ReferencesCtxProvider } from '../features/context/references';

import AppBar from '../features/_layout/components/AppBar';
import SideMenu from '../features/_layout/components/SideMenu';

import ByFirm from '../features/board/by_firm';
import FirmDashboard from '../features/board/firm_dashboard';
import Dashboard from '../features/board/dashboard';
import MyTasks from '../features/board/my_tasks';
import Task from '../features/board/task';
import Reports from '../features/board/reports';
import TaskCategoriesWithContext from '../features/board/task_categories';
import KnowledgeBase from '../features/board/knowledge_base';

import ManageUser from '../features/manage/users';
import ManageCategory from '../features/manage/categories';
import ManageFirms from '../features/manage/firms';
import ManageProjects from '../features/manage/projects';
import ManageRecurringTasks from '../features/manage/recurring_tasks';
import ManageTaskRequests from '../features/manage/task_requests';
import ChangePassword from '../features/profile/ChangePassword';
import ChangeProfilePic from '../features/profile/ChangeProfilePic';
import AccountingTask from '../features/manage/firms/components/accounting-task/AccountingTask';

import ReportWeeklySummary from '../features/reports/weekly_summary';
import ReportUserWeeklySummary from '../features/reports/users';
import ReportUserData from '../features/reports/user_data';
import ReportComparative from '../features/reports/comparative';
import ReportTaskRequests from '../features/reports/task_requests';
import ReportUserProfile from '../features/reports/user_profiles';
import ReportTaskWeeklyCount from '../features/reports/task_weekly_count';
import DailyTaskReports from '../features/reports/daily_task_reports';
import WeeklyTaskReports from '../features/reports/weekly_task_reports';
import WeeklyFirmReports from '../features/reports/weekly_firm_reports/WeeklyFirmReports';
import FirmTasksReports from '../features/reports/firm_tasks_reports/FirmTasksReports';
import AccountTeTvReports from '../features/reports/account_te_tv_reports/AccountTeTvReports';
import TimeVarianceReport from '../features/reports/time_variance/TimeVarianceReport';
import AccountingTimeVarianceReport from '../features/reports/accounting_time_variance/AccountingTimeVarianceReport';
import DataReport from '../features/reports/data_reports/DataReport';

import ScrollAnchor from '../shared/components/utils/_anchor';
import StopImpersonate from '../shared/components/utils/_stop_impersonate';

import Layout from '../features/_layout/Layout';

import { Provider } from 'react-redux';
import store from '../store';
import ScopeVarianceReport from '../features/reports/scope_variance/ScopeVarianceReport';

export default () => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <GlobalCtxProvider>
          <ReferencesCtxProvider>
          <PermissionCtxProvider>
            <FirmTasksCtxProvider>
              <Layout AppBar={AppBar} SideMenu={SideMenu}>
                <ScrollAnchor />
                <StopImpersonate />
                <Router basepath="app">
                  <ByFirm path="/firm/:firm_id" />
                  <FirmDashboard path="/firm/:firm_id/dashboard" />
                  <Task path="/task/:task_id" />
                  <ManageUser path="/manage/users/*" />
                  <ManageCategory path="/manage/categories/*" />
                  <ManageFirms path="/manage/firms/*" />
                  <AccountingTask path="manage/accounting_task/:firm_id" />
                  <ManageProjects path="/manage/projects/*" />
                  <ManageRecurringTasks path="/manage/recurring_tasks/*" />
                  <ManageTaskRequests path="/manage/task_requests/*" />
                  <ChangePassword path="/profile/change_password" />
                  <ChangeProfilePic path="/profile/change_profile_pic" />
                  <MyTasks path="/my_tasks" />
                  <TaskCategoriesWithContext path="/task_categories" />
                  <KnowledgeBase path="/knowledge_base" />

                  <Reports path="/reports" />
                  <ReportWeeklySummary path="/reports/weekly_summary" />
                  <ReportUserWeeklySummary path="/reports/user_weekly_summary" />
                  <ReportUserData path="/reports/user_data" />
                  <ReportComparative path="/reports/comparative" />
                  <ReportTaskRequests path="/reports/task_requests" />
                  <ReportUserProfile path="/reports/user/:user_id" />
                  <ReportTaskWeeklyCount path="/reports/task_weekly_count" />
                  <DailyTaskReports path="/reports/daily_task_reports" />
                  <WeeklyTaskReports path="/reports/weekly_task_reports" />
                  <WeeklyFirmReports path="/reports/weekly_firm_reports" />
                  <FirmTasksReports path="/reports/firm_tasks_reports" />
                  <AccountTeTvReports path="/reports/account_te_tv_reports" />
                  <DataReport type={'week'} path="/reports/data_report_weekly" />
                  <DataReport type={'month'} path="/reports/data_report_monthly"/>
                  <TimeVarianceReport path="/reports/time_variance" />
                  <ScopeVarianceReport path="/reports/scope_variance" />
                  <AccountingTimeVarianceReport path="/reports/accounting_time_variance" />

                  <Dashboard path="/all" />
                  <Dashboard path="/" />
                </Router>
              </Layout>
            </FirmTasksCtxProvider>
          </PermissionCtxProvider>
        </ReferencesCtxProvider>
        </GlobalCtxProvider>
      </SnackbarProvider>
    </Provider>
  );
};
