import React from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteUserButton from "../DeleteUserButton";

import { usePermission } from "../../../../../context/permission";

const UserListItem = ({ row, firm }) => {
  const { isAtleastAngel } = usePermission();
  // Only angels can delete other angels
  const showDeleteButton = isAtleastAngel() || row.role !== "angel";

  return (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        {row.label}
      </TableCell>
      <TableCell align="left">{row.role}</TableCell>
      <TableCell align="right">
        {showDeleteButton && <DeleteUserButton firm={firm} user={row} />}
      </TableCell>
    </TableRow>
  );
};

export default UserListItem;
