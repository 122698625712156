import React, { useState } from 'react';
import { useFirmsCtx } from '../context';
import { useGlobal } from '../../../context/global';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditAccount from './_edit_account';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import SinglePhotoUpload from '../../../../shared/components/upload_photo/single';

import { API_PATH, API_MANAGE_ACCOUNTS } from '../../../../const/api_paths';
import { doAuthenticatedDelete } from '../../../../actions/_methods';

const useStyles = makeStyles((theme) => ({
  form_wrapper: {
    display: 'flex',
    width: '500px',
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export default ({ account }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { setIsLoading, reloadData } = useFirmsCtx();
  const [edit_mode, setEditMode] = useState(false);
  const [upload_photo_mode, setUploadPhotoMode] = useState(false);

  const upload_url = () => {
    return API_PATH(`${API_MANAGE_ACCOUNTS}/${account.id}/upload`);
  };

  const onDelete = () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete account: "${account.name}"`
    );

    if (confirmed) {
      setIsLoading('secondary');
      doAuthenticatedDelete(
        API_MANAGE_ACCOUNTS + '/' + account.id,
        {},
        {
          general: onSuccess,
          success: onSuccess,
          error: onError,
        }
      );
    }
  };

  const onSuccess = (res) => {
    const message = `Successfully removed account: ${res.account.name}`;

    reloadData('secondary');
    prompt(message);
  };

  const onError = (res) => {
    alert(`Please try again...`);
  };

  const onShowEdit = () => {
    setEditMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
  };

  const onCancelUploadPhoto = () => {
    setUploadPhotoMode(false);
  }

  const onShowUploadPhoto = () => {
    setUploadPhotoMode(true);
  };

  const localAfterEdit = (account) => {
    const message = `Successfully edited account: ${account.name}`;

    setEditMode(false);
    reloadData('secondary');
    prompt(message);
  };

  const localAfterUploadPhoto = () => {
    const message = 'Successfully uploaded a logo';

    setUploadPhotoMode(false);
    reloadData('secondary');
    prompt(message);
  };

  const EditButton = () => (
    <IconButton
      color="warning"
      className={classes.button}
      onClick={onShowEdit}
      aria-label="edit"
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  const DeleteButton = () => (
    <IconButton
      color="secondary"
      className={classes.button}
      onClick={onDelete}
      aria-label="delete"
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );

  const UploadPhotoButton = () => (
    <IconButton
      color="info"
      className={classes.button}
      onClick={onShowUploadPhoto}
      aria-label="upload_photo"
    >
      <AddAPhotoIcon fontSize="small" />
    </IconButton>
  );

  return (
    <div>
      {edit_mode && (
        <EditAccount
          account={account}
          name={account.name}
          afterSave={localAfterEdit}
          onCancel={onCancel}
        />
      )}
      {!edit_mode && <EditButton />}
      {!edit_mode && <DeleteButton />}
      {!edit_mode && !upload_photo_mode && <UploadPhotoButton />}
      {!edit_mode && upload_photo_mode && (
        <SinglePhotoUpload
          url={upload_url()}
          afterSave={localAfterUploadPhoto}
          onCancel={onCancelUploadPhoto}
        />
      )}
    </div>
  );
};
