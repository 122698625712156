import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";
import { API_PATH, DATA_DAILY_HOURS_REPORT } from "../../../const/api_paths";

const useStyles = makeStyles({
  card: {
    marginTop: "10px",
    border: "1px solid #00000029",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  },
  wrapper: {
    display: "flex",
    gap: 30,
    width: "50%",
  },
  button: {
    backgroundColor: "#3059fb",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2b50e2",
    },
  },
});

const headerStyles = {
  color: "#23282C",
  fontWeight: 500,
};

const InstanceDailyReport = () => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const downloadReport = () => {
    const queryParams = new URLSearchParams({
      date: date,
    }).toString();
    const endpoint = API_PATH(`${DATA_DAILY_HOURS_REPORT}.csv?${queryParams}`);

    window.location = endpoint;
  };

  const handleChangeDay = (event) => {
    console.log(event.target.value);
    setDate(event.target.value);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Daily Instance Report"
        className={classes.header}
        titleTypographyProps={headerStyles}
      />
      <CardContent>
        <Box className={classes.container}>
          <Box className={classes.wrapper}>
            <TextField
              label="Report Date"
              type="date"
              sx={{ width: "25%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              defaultValue={date}
              onChange={handleChangeDay}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={downloadReport}
            >
              Download
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InstanceDailyReport;
