import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Chip, TextField, Autocomplete } from "@mui/material";

import { DEFAULT_TAGS } from "../../const/ref";

const useStyles = makeStyles((_theme) => ({
  box: {
    width: "100%",
  },
}));

const name = "tags";
const title = "Tags";

export default ({ onChange, taskCategoryName }) => {
  const classes = useStyles();
  const [value, setValue] = useState([]);

  const onChangeLocal = (e, target) => {
    setValue(target);
    onChange(sendBackData(target));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value;
    return data;
  };

  const defaultTags = DEFAULT_TAGS[taskCategoryName] || [];

  return (
    <Box className={classes.box}>
      <Autocomplete
        multiple
        freeSolo
        size="small"
        options={defaultTags}
        onChange={onChangeLocal}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={title} variant="outlined" />
        )}
      />
    </Box>
  );
};
