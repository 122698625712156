import React, { useState, useEffect } from 'react';
import { IfAnyGranted } from "react-authorization";
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import BasicFilters from './components/BasicFilters';
import BasicTodayFilters from './components/BasicTodayFilters';
import ModeSwitcher from './components/_tasks/ModeSwitcher';
import TasksView from './components/_tasks/Main';
import LoadingTasks from './components/_tasks/LoadingTasks';
import PreviewTask from './components/PreviewTask';
import CallToActionAlert from '../../shared/components/CallToActionAlert';

import { API_MANAGE_FIRM_PREFERENCES, API_TASKS } from '../../const/api_paths';
import { doAuthenticatedGet } from '../../actions/_methods';

import { STYLES } from './const/styles';
import moment from 'moment';
import { useGlobal } from '../context/global';
import { useFirmTasks } from '../context/firm_tasks';
import { useReferences } from '../context/references';
import { usePermission } from "../context/permission";

const useStyles = makeStyles((theme) => STYLES(theme));

export default ({ firm_id }) => {
  const classes = useStyles();
  const { loadFirmOrganization, loadCategories, loadTaskCategories, loadStuckOptions } = useReferences();
  const { setMyself, setFirmUsers, getSelectedFirm, setPageKnowledgeBaseTags } = useGlobal();
  const { setTasks, setPreferences, resetSortParameters, loadFirmData } = useFirmTasks();
  const [group_by, setGroupBy] = useState('firm');
  const [filters, setFilters] = useState({ firm_id: parseInt(firm_id) });
  const [references, setReferences] = useState({});
  const [view_mode, setViewMode] = useState('today');
  const [loading, setLoading] = useState(true);
  const firmData = getSelectedFirm();
  const firmTags = firmData.tags;
  const tagsToAvoid = ['Data', 'OEO'];
  const showUncompleted = firmTags.every((tag) => !tagsToAvoid.includes(tag));
  const showAllUncompleted =
    view_mode === 'uncompleted' && showUncompleted && !filters.due_date;
  const { atleastAngel, getMyRoles } = usePermission();

  useEffect(() => {
    loadFirmOrganization(firm_id);
    loadFirmData(firm_id);
    loadCategories();
    loadTaskCategories();
    loadStuckOptions();
    setPageKnowledgeBaseTags(["Adding Tasks", "Requesting Tasks"]);
  }, []);

  useEffect(() => {
    const new_filters = { ...filters };
    if (view_mode === 'today') {
      new_filters['due_date'] = moment().format('YYYY-MM-DD');
      setFilters(new_filters);
      loadData(new_filters);
    }

    if (showAllUncompleted) {
      loadData(uncompletedTaskFilters);
    }
  }, [view_mode]);

  const uncompletedTaskFilters = {
    firm_id: parseInt(firm_id),
    date_range: 'all',
    uncompleted: 'true',
  };

  const loadData = (params) => {
    setLoading(true);
    doAuthenticatedGet(API_TASKS, params, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  };

  const onSuccess = (res) => {
    const tasks = res['tasks'];
    const references = res['references'];

    setTasks(
      view_mode === 'uncompleted'
        ? tasks.filter((task) => task.status !== 'completed')
        : tasks
    );

    setMyself(res['myself']);
    setReferences(references);
    setFirmUsers(references.assigned_users);
    setLoading(false);
  };

  const onError = (res) => {
    console.log(res);
    alert('Unable to fetch the tasks, please try again.');
    setLoading(false);
  };

  const onChangeFilter = (data) => {
    resetSortParameters();
    setFilters(data);
    loadData(data);
  };

  const onChangeViewMode = (mode) => {
    setViewMode(mode);
    resetSortParameters();

    const new_filters = { firm_id: parseInt(firm_id) };
    if (mode == 'mytoday') {
      new_filters['due_date'] = moment().format('YYYY-MM-DD');
      new_filters['view_mode'] = 'mytoday';
      setFilters(new_filters);
      loadData(new_filters);
    } else if (mode == 'today') {
      new_filters['due_date'] = moment().format('YYYY-MM-DD');
      setFilters(new_filters);
      loadData(new_filters);
    } else {
      delete new_filters.due_date;
      setFilters(new_filters);
      setTasks([]);
    }
  };

  const preferencesEndpoint = () => {
    return API_MANAGE_FIRM_PREFERENCES.replace(':firm_id', firm_id);
  };

  const loadPreferences = () => {
    doAuthenticatedGet(
      preferencesEndpoint(),
      {},
      {
        success: (res) => setPreferences(res.preferences),
        general: (res) => setPreferences(res.preferences),
        error: (res) => console.error(res),
      }
    );
  };

  useEffect(() => {
    loadPreferences();
  }, [firm_id]);

  return (
    <div>
      <CallToActionAlert />
      <PreviewTask />
      <Box sx={{ 
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Box>
          <IfAnyGranted expected={atleastAngel()} actual={getMyRoles()}>
            <Button
              href={`/app/firm/${firm_id}/dashboard`}
              color="primary"
            >
              Dashboard
            </Button>
          </IfAnyGranted>
        </Box>
        <ModeSwitcher
          current_mode={view_mode}
          onChangeMode={onChangeViewMode}
          showUncompleted={showUncompleted}
        />
      </Box>
      {view_mode == 'today' && (
        <BasicTodayFilters
          classes={classes}
          references={references}
          onChange={onChangeFilter}
          no_organization={true}
          default_filter={{ firm_id: parseInt(firm_id) }}
        />
      )}
      {view_mode != 'today' && (
        <BasicFilters
          classes={classes}
          references={references}
          onChange={onChangeFilter}
          no_organization={true}
          default_filter={{ firm_id: parseInt(firm_id) }}
          view_mode={view_mode}
        />
      )}
      <LoadingTasks loading={loading} />
      <TasksView group_by={group_by} view_mode={view_mode} filters={filters} />
    </div>
  );
};
