import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MenuItem, Select, TextField, Box } from "@mui/material";
import { format } from "date-fns";

const QuarterPicker = ({ onChange }) => {
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [selectedQuarter, setSelectedQuarter] = useState("Q1");

  // Get the current quarter
  const getCurrentQuarter = () => {
    const month = new Date().getMonth();
    return `Q${Math.floor(month / 3) + 1}`;
  };

  // Ensure future quarters are disabled
  const isQuarterDisabled = (quarter, year) => {
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    return (
      year > currentYear || (year === currentYear && quarter > currentQuarter)
    );
  };

  // Handle changes and trigger the onChange callback
  const handleChange = (year, quarter) => {
    setSelectedYear(year);
    setSelectedQuarter(quarter);
    if (onChange) {
      onChange({ year: format(year, "yyyy"), quarter });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display="flex" gap={2} alignItems="center">
        {/* Year Picker */}
        <DatePicker
          views={["year"]}
          label="Select Year"
          value={selectedYear}
          onChange={(newValue) => handleChange(newValue, selectedQuarter)}
          maxDate={new Date()} // Prevents selecting future years
          renderInput={(params) => <TextField size="small" {...params} />}
        />

        {/* Quarter Dropdown */}
        <Select
          value={selectedQuarter}
          size="small"
          onChange={(e) => handleChange(selectedYear, e.target.value)}
          displayEmpty
        >
          {["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
            <MenuItem
              key={quarter}
              value={quarter}
              disabled={isQuarterDisabled(quarter, selectedYear.getFullYear())}
            >
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* Display Selected Quarter */}
      <h3>
        Selected Quarter: {format(selectedYear, "yyyy")}-{selectedQuarter}
      </h3>
    </LocalizationProvider>
  );
};

export default QuarterPicker;
