import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Name from "./_add_task/Name";
import AccountLocation from "./_add_task/AccountLocation";
import StartDate from "./_add_task/StartDate";
import DueDate from "./_add_task/DueDate";
import AssignedTo from "./_add_task/AssignedTo";
import TaskCategory from "./_add_task/TaskCategory";
import SopAccountingTaskTemplate from "./_add_task/AccountingTaskTemplate";
import SopFileUpload from "../../../shared/components/upload/TaskSop";
import Tags from "./_add_task/Tags";
import Checklist from "./_add_task/Checklist";
import Description from "./_add_task/Description";
import { doAuthenticatedPostFormData } from "../../../actions/_methods";
import { API_TASKS } from "../../../const/api_paths";
import { useSnackbar } from "notistack";
import { useGlobal } from "../../context/global";
import { useFirmTasks } from "../../context/firm_tasks";
import { Box, Card, CardHeader, CardContent, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  inner_box: {
    display: "flex",
    marginTop: "25px",
  },
  buttons_box: {
    marginTop: "25px",
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel-btn": {
      marginLeft: "15px",
    },
  },
}));

export default ({
  onCloseAddNewTask,
  onAddData,
  group_by,
  group_id,
  filters,
}) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [isSopUpload, setIsSopUpload] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const { firmUsers, getSelectedFirm } = useGlobal();
  const { preferences } = useFirmTasks();
  const firmData = getSelectedFirm();
  const firmTags = firmData.tags;

  const allowAddName = preferences.firm_v2 === "false";
  const showStartDate = firmTags.includes("Tech");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    clearData();
  }, []);

  const clearData = () => {
    setData({});
    setData({ firm_id: filters.firm_id, billable: false });
  };

  const doAddTask = (params) => {
    var formData = new FormData();
    for (var key in params) {
      const value = params[key];
      // If value is an array, stringify it first since we are using FormData
      if (Array.isArray(value) && value[0] instanceof File) {
        value.forEach((file, index) => {
          formData.append(`${key}[${index}]`, file);
        });
      } else if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }

    doAuthenticatedPostFormData(API_TASKS, formData, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
      catch: () => {
        setDisableSaveButton(false);
      },
    });
  };

  const onClickAddTask = () => {
    let error = validateTask();
    if (error.length > 0) {
      enqueueSnackbar(error.join("\n"), { variant: "error" });
    } else {
      setDisableSaveButton(true);
      doAddTask(data);
    }
  };

  const validateTask = () => {
    let error = [];
    if (data['task_category_id'] == undefined || data['task_category_id'] == '') {
      error.push('Sub Category is required.')
    }

    return error;
  };

  const onChange = (new_data) => {
    const params = { ...data, ...new_data };
    setData(params);
    if (["SOP Update", "SOP New"].includes(params["task_category_name"]))
      setIsSopUpload(true);
    else setIsSopUpload(false);
  };

  const onSuccess = (res) => {
    setDisableSaveButton(false);
    clearData();
    enqueueSnackbar("You have successfully ADDED new task.", {
      variant: "success",
    });
    onAddData(res.task);
  };

  const onError = (res) => {
    setDisableSaveButton(false);
    let errorMessage = "Unable to add the task:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" - ");
    }
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
  };

  const afterSopFileUpload = (file) => {
    // Append file to sop_update_files array
    const sop_update_files = data.sop_update_files || [];
    sop_update_files.push(file);
    setData({ ...data, sop_update_files: sop_update_files });
  };

  const afterSopFileRemove = (file) => {
    // Remove file to sop_update_files array
    const sop_update_files = data.sop_update_files || [];
    sop_update_files.splice(sop_update_files.indexOf(file), 1);
    setData({ ...data, sop_update_files: sop_update_files });
  };

  const SaveTaskButton = () => (
    <Button
      variant="contained"
      color="primary"
      disabled={disableSaveButton}
      onClick={onClickAddTask}
    >
      Add New Task
    </Button>
  );

  const CancelButton = () => (
    <Button
      variant="contained"
      className="cancel-btn"
      onClick={onCloseAddNewTask}
    >
      Cancel
    </Button>
  );

  return (
    <Card className={classes.box} id="add_new_task_section">
      <CardHeader title="Add New Task" />
      <CardContent>
        {allowAddName && (
          <Box>
            <Name onChange={onChange} />
          </Box>
        )}
        <Box className={classes.inner_box}>
          <AccountLocation
            onChange={onChange}
            firm_id={filters.firm_id}
            {...{ group_by, group_id }}
          />
        </Box>
        <Box className={classes.inner_box}>
          {showStartDate && <StartDate onChange={onChange} />}
          <DueDate onChange={onChange} />
          <AssignedTo onChange={onChange} options={firmUsers} />
          <TaskCategory onChange={onChange} required={false} />
          {/* <Category onChange={onChange} default_value="" /> */}
        </Box>
        {isSopUpload && (
          <Box className={classes.inner_box}>
            <SopAccountingTaskTemplate data={data} onChange={onChange} />
          </Box>
        )}
        <Box className={classes.inner_box}>
          <Tags onChange={onChange} taskCategoryName={data.task_category_name} />
        </Box>
        <Box className={classes.inner_box}>
          <Checklist onChange={onChange} default_value={[]} />
        </Box>
        <Box className={classes.inner_box}>
          <Description onChange={onChange} />
        </Box>
        {isSopUpload && (
          <Box className={classes.inner_box}>
            <SopFileUpload
              afterUpload={afterSopFileUpload}
              afterRemove={afterSopFileRemove}
            />
          </Box>
        )}
        <Box className={classes.buttons_box}>
          <SaveTaskButton />
          <CancelButton />
        </Box>
        <Box>{data.length}</Box>
      </CardContent>
    </Card>
  );
};
