import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Autocomplete } from "@mui/material";

const useStyles = makeStyles({
  container: {
    width: (props) => props.width,
  },
  input: {
    width: "100%",
    marginRight: "10px",
  },
  avatar_wrapper: {
    "& > div": {
      width: "28px",
      height: "28px",
      marginRight: "10px",
    },
  },
});

export default ({ name, title, default_value, options, onChange, width }) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState(default_value);

  const onChangeLocal = (e, target_value) => {
    setValue(target_value);
    onChange(sendBackData(target_value));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value?.value ?? "";
    return data;
  };

  const OptionComponent = ({ option, props }) => {
    return (
      <div className={classes.option} {...props}>
        {option.label}
      </div>
    );
  };

  const InputComponent = ({ params, title }) => (
    <TextField {...params} label={title} variant="outlined" />
  );

  return (
    <div className={classes.container}>
      <Autocomplete
        multiple={false}
        size="small"
        options={options}
        limitTags={1}
        className={classes.input}
        onChange={onChangeLocal}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        renderInput={(params) => <InputComponent {...{ params, title }} />}
      />
    </div>
  );
};
