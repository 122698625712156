import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Paper, Typography } from "@mui/material";

import InputAutocompleteSingle from "../components/_basic_filters/InputAutocompleteSingle";
import InputPerson from "../components/_basic_filters/InputPerson";

const useStyles = makeStyles({
  paper: {
    padding: "20px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginBottom: "20px",
  },
  marginBottom: {
    marginBottom: "20px",
  },
});

const Filters = ({ accounts, users, onChange }) => {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.paper}>
      <Typography variant={"h6"}>Filters</Typography>
      <Divider className={classes.marginBottom} />
      <Box display="flex" justifyContent="left" gap={2}>
        <InputAutocompleteSingle
          width="30%"
          name="account_id"
          title="Account"
          options={accounts}
          onChange={onChange}
          default_value=""
        />
        <InputPerson
          width="30%"
          name="user_id"
          title="Person"
          options={users}
          onChange={onChange}
        />
      </Box>
    </Paper>
  );
};

export default Filters;
