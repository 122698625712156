import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";

import { doAuthenticatedPut } from "../../../../../actions/_methods";
import { API_MANAGE_PROJECTS_SET_TAGS } from "../../../../../const/api_paths";
import { projectActions } from "../../../../../store/project_slice";

export default ({ task }) => {
  const dispatch = useDispatch();
  const [selecting, setSelecting] = useState(false);
  const [tags, setTags] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTags(task.tags);
  }, []);

  const onChangeTags = (_event, value) => {
    setTags(value);
  };

  const onSubmit = () => {
    const url = API_MANAGE_PROJECTS_SET_TAGS.replace(":firm_id", task.firm_id);
    const data = { tags: tags, task_id: task.id };

    setSubmitting(true);
    doAuthenticatedPut(url, data, {
      general: onSuccess,
      success: onSuccess,
      error: onError,
    });
  };

  const onSuccess = (res) => {
    dispatch(projectActions.updateCategoryTask({ task: res.task }));

    setSubmitting(false);
    setSelecting(!selecting);
    enqueueSnackbar("You have successfully UPDATED the tags.", {
      variant: "success",
    });
  };

  const onError = (_res) => {
    enqueueSnackbar("Please try again", { variant: "error" });
    setSubmitting(false);
  };

  return (
    <>
      <Button onClick={() => setSelecting(!selecting)}>Manage Tags</Button>
      {!selecting && (
        <Box style={{ paddingLeft: "30px" }}>
          {task.tags.map((tag) => {
            return <Chip style={{ marginRight: "2px" }} label={tag} />;
          })}
        </Box>
      )}
      {selecting && (
        <>
          <Box style={{ width: "100%" }}>
            <Paper
              elevation={3}
              style={{ width: "100%", padding: "15px 20px" }}
            >
              <div style={{ display: "flex" }}>
                <Autocomplete
                  multiple
                  freeSolo
                  size="small"
                  style={{ flexGrow: "1" }}
                  value={tags}
                  options={[]}
                  onChange={onChangeTags}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Tags" variant="outlined" />
                  )}
                />
                <Button
                  variant="contained"
                  onClick={onSubmit}
                  disabled={submitting}
                  style={{ marginLeft: "20px" }}
                >
                  Add Tags
                </Button>
              </div>
            </Paper>
          </Box>
        </>
      )}
    </>
  );
};
