import React from "react";
import Dropdown from "./Dropdown";
import Toggle from "./Toggle";
import { makeStyles } from "@mui/styles";
import { IfAnyGranted } from "react-authorization";
import { usePermission } from "../../../../context/permission";

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
  },
  section: {
    borderBottom: "1px solid #00000029",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  regularPad: {
    padding: "20px 20px 20px 20px",
  },
  smallPad: {
    padding: "10px 20px 10px 20px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
  },
});

const Options = ({ preferences, setPreferences }) => {
  const { atleastAngel, getMyRoles } = usePermission();
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <div className={classes.section}>
        <div className={`${classes.content} ${classes.regularPad} `}>
          <span className={classes.title}>Time Zone</span>
          <Dropdown
            width={260}
            timezone={preferences.timezone}
            setPreferences={setPreferences}
          />
        </div>
      </div>
      <IfAnyGranted expected={atleastAngel()} actual={getMyRoles()}>
        <div className={classes.section}>
          <div className={`${classes.content} ${classes.smallPad} `}>
            <span className={classes.title}>Create Weekly Task</span>
            <Toggle
              on={preferences.weekly_task_auto_generate}
              preferenceKey="weekly_task_auto_generate"
              setPreferences={setPreferences}
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={`${classes.content} ${classes.smallPad} `}>
            <span className={classes.title}>Pass The Baton</span>
            <Toggle
              on={preferences.pass_the_baton}
              preferenceKey="pass_the_baton"
              setPreferences={setPreferences}
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={`${classes.content} ${classes.smallPad} `}>
            <span className={classes.title}>Enable Add Task</span>
            <Toggle
              on={preferences.enable_add_task}
              preferenceKey="enable_add_task"
              setPreferences={setPreferences}
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={`${classes.content} ${classes.smallPad} `}>
            <span className={classes.title}>Show Page Count</span>
            <Toggle
              on={preferences.show_page_count}
              preferenceKey="show_page_count"
              setPreferences={setPreferences}
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={`${classes.content} ${classes.smallPad} `}>
            <span className={classes.title}>Nightly Report</span>
            <Toggle
              on={preferences.nightly_report}
              preferenceKey="nightly_report"
              setPreferences={setPreferences}
            />
          </div>
        </div>
      </IfAnyGranted>
      <div className={classes.section}>
        <div className={`${classes.content} ${classes.smallPad} `}>
          <span className={classes.title}>Firm V2</span>
          {preferences.firm_v2 === "true" ? "Yes" : "No"}
        </div>
      </div>
    </div>
  );
};

export default Options;
