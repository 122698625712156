import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Stack,
  CardContent,
  Card,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { useSnackbar } from "notistack";

import { API_PATH } from "../../../const/api_paths";
import { groupByLevel } from "../../../utils/orgs";
import InputAutocompleteSingle from "../../board/components/_basic_filters/InputAutocompleteSingle";
import { useReferences } from "../../context/references";

import QuarterPicker from "./components/QuarterPicker";

const useStyles = makeStyles((_theme) => ({
  card: {
    marginTop: "10px",
    border: "1px solid #9f9f9f",
  },
  reportBox: {
    marginTop: "20px",
  },
  reportStack: {
    marginTop: "10px",
  },
}));

export default () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    basic_organizations,
    loaded_basic_organizations,
    loadBasicOrganizations,
    myself,
  } = useReferences();

  // Get the current quarter
  const getCurrentQuarter = () => {
    const month = new Date().getMonth();
    return `Q${Math.floor(month / 3) + 1}`;
  };

  const defaultFilters = {
    month: moment().format("YYYY-MM"),
    quarter: `${moment().format("YYYY")}-${getCurrentQuarter()}`,
    firm_id: "",
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [firms, setFirms] = useState([]);
  const [enableButton, setEnableButton] = useState(false);

  useEffect(() => {
    loadBasicOrganizations();
    // Load the initial data
    setFilters({ ...filters });
  }, []);

  useEffect(() => {
    if (basic_organizations.length > 0) {
      const { level_1 } = groupByLevel(basic_organizations);
      setFirms(level_1);
    }
  }, [basic_organizations]);

  useEffect(() => {
    setEnableButton(!!filters.firm_id);
  }, [filters, loaded_basic_organizations]);

  const handleGenerateReport = (period) => {
    const encoded = window.btoa(`${window.crypto.randomUUID()}--${myself.id}`);
    var target_url = API_PATH(
      `/reports/comparative.csv?${dataToParams()}&period=${period}&uid=${encoded}`
    );
    window.location = target_url;
  };

  const handleGenerateMonthReport = () => {
    handleGenerateReport("month");
  };

  const handleGenerateQuarterReport = () => {
    handleGenerateReport("quarter");
  };

  const dataToParams = () => {
    const new_data = { ...filters };
    Object.keys(new_data).forEach((key) => {
      if (new_data[key] === null) {
        delete new_data[key];
      }
    });

    return new URLSearchParams(new_data).toString();
  };

  const handleChange = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const setSelectedMonth = (newValue) => {
    const newFilters = {
      ...filters,
      ...{
        month: moment(newValue).format("YYYY-MM"),
      },
    };
    setFilters(newFilters);
  };

  const setSelectedQuarter = (newValue) => {
    const newFilters = {
      ...filters,
      ...{
        quarter: `${moment(newValue.year).format("YYYY")}-${newValue.quarter}`,
      },
    };
    setFilters(newFilters);
  };

  return (
    <Card className={classes.card}>
      <CardHeader title="Month/Quarter Comparative Report" />
      <CardContent>
        <Box>
          <InputAutocompleteSingle
            width={"40%"}
            name="firm_id"
            title="Firm"
            options={firms}
            onChange={handleChange}
            default_value=""
          />
        </Box>

        <Box className={classes.reportBox}>
          <Typography variant="h6">Month On Month</Typography>
          <Stack direction="row" spacing={3} className={classes.reportStack}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year", "month"]} // Shows only year and month selection
                openTo="month"
                label="Select Month"
                maxDate={new Date()} // Disables future dates
                value={filters.month}
                onChange={(newValue) => setSelectedMonth(newValue)}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              color="success"
              onClick={handleGenerateMonthReport}
              disabled={!enableButton}
            >
              Download Report
            </Button>
          </Stack>
        </Box>

        <Box className={classes.reportBox}>
          <Typography variant="h6">Quarter On Quarter</Typography>
          <Stack direction="row" spacing={3} className={classes.reportStack}>
            <QuarterPicker
              onChange={(newValue) => setSelectedQuarter(newValue)}
            />
            <Button
              variant="contained"
              color="success"
              onClick={handleGenerateQuarterReport}
              disabled={!enableButton}
            >
              Download Report
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
