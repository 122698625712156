import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { doAuthenticatedGet } from "../../../actions/_methods";
import {
  FIRM_DASHBOARDS_FIRM_DATA,
  FIRM_DASHBOARDS_CHART_DATA,
} from "../../../const/api_paths";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  // AreaChart,
  // Area,
  PieChart,
  Pie,
  Cell,
  // ScatterChart,
  // Scatter,
  // RadarChart,
  // Radar,
  // PolarGrid,
  // PolarAngleAxis,
  // PolarRadiusAxis,
  // ComposedChart,
  // RadialBarChart,
  // RadialBar,
  // Treemap,
} from "recharts";

import Filters from "./filters";

const FirmDashboard = ({ firm_id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [firm, setFirm] = useState({ account_options: [], user_options: [] });
  const [chartData, setChartData] = useState({});
  const [filters, setFilters] = useState({});

  const fetchFirmData = () => {
    doAuthenticatedGet(
      FIRM_DASHBOARDS_FIRM_DATA,
      { firm_id: firm_id },
      {
        success: onSuccessFirmData,
        general: onSuccessFirmData,
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccessFirmData = (res) => {
    setFirm(res.data);
  };

  const fetchChartData = () => {
    doAuthenticatedGet(
      FIRM_DASHBOARDS_CHART_DATA,
      { firm_id: firm_id, ...filters },
      {
        success: onSuccess,
        general: onSuccess,
        error: (res) => console.error(res),
      }
    );
  };

  const onSuccess = (res) => {
    setChartData(res.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFirmData();
    fetchChartData();
  }, []);

  useEffect(() => {
    fetchChartData();
  }, [filters]);

  const onChangeFilters = (newFilters) => {
    setIsLoading(true);
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const LoadingBox = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={300}
    >
      <CircularProgress />
    </Box>
  );

  const ChartTitle = ({ title }) => (
    <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
      <Typography variant="h6">{title}</Typography>
    </Box>
  );

  const currentWeek = () => {
    const curr = new Date();
    const monday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    const saturday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    return `${monday.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
    })} - ${saturday.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
    })}`;
  };

  const PIE_COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff7f50", "#a83279"];

  return (
    <Box>
      {firm && (
        <Box>
          <Typography variant="h4" gutterBottom>
            {firm.name}
          </Typography>

          <Filters
            accounts={firm.account_options}
            users={firm.user_options}
            onChange={onChangeFilters}
          />
        </Box>
      )}

      <Typography variant="h6" gutterBottom>
        Weekly Stats
      </Typography>
      <Grid container spacing={2}>
        {/* First Row */}
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <ChartTitle title="Number of Tasks Per Week" />
              {isLoading ? (
                <LoadingBox />
              ) : (
                <Box display="flex" justifyContent="center">
                  <LineChart
                    width={650}
                    height={300}
                    data={chartData["task_count_weekly"]}
                  >
                    {/* <Line
                      type="monotone"
                      dataKey="task_count"
                      stroke="#8884d8"
                      name="Tasks"
                    /> */}
                    <Line
                      type="monotone"
                      dataKey="task_count_cta"
                      stroke="#ff0000"
                      name="CTA"
                    />
                    <Line
                      type="monotone"
                      dataKey="task_count_external"
                      stroke="#1aab3b"
                      name="External"
                    />
                    <Line
                      type="monotone"
                      dataKey="task_count_internal"
                      stroke="#1a35ab"
                      name="Internal"
                    />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(date) =>
                        new Date(date).toLocaleString("en-US", {
                          month: "short",
                          day: "2-digit",
                        })
                      }
                    />
                    <YAxis />
                    <Tooltip />
                  </LineChart>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent>
              <ChartTitle title="Hours Worked Per Week" />
              {isLoading ? (
                <LoadingBox />
              ) : (
                <Box display="flex" justifyContent="center">
                  <LineChart
                    width={650}
                    height={300}
                    data={chartData["hours_worked_weekly"]}
                  >
                    {/* <Line
                      type="monotone"
                      dataKey="hours_worked"
                      stroke="#8884d8"
                      name="Total Hours"
                    /> */}
                    <Line
                      type="monotone"
                      dataKey="hours_worked_external"
                      stroke="#1aab3b"
                      name="Hours (External)"
                    />
                    <Line
                      type="monotone"
                      dataKey="hours_worked_internal"
                      stroke="#1a35ab"
                      name="Hours (Internal)"
                    />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(date) =>
                        new Date(date).toLocaleString("en-US", {
                          month: "short",
                          day: "2-digit",
                        })
                      }
                    />
                    <YAxis />
                    <Tooltip />
                  </LineChart>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
        Monthly Stats
      </Typography>
      <Grid container spacing={2}>
        {/* Second Row */}
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <ChartTitle title="Number of Tasks Per Month" />
              {isLoading ? (
                <LoadingBox />
              ) : (
                <Box display="flex" justifyContent="center">
                  <LineChart
                    width={650}
                    height={300}
                    data={chartData["task_count_monthly"]}
                  >
                    {/* <Line
                      type="monotone"
                      dataKey="task_count"
                      stroke="#8884d8"
                      name="Tasks"
                    /> */}
                    <Line
                      type="monotone"
                      dataKey="task_count_cta"
                      stroke="#ff0000"
                      name="CTA"
                    />
                    <Line
                      type="monotone"
                      dataKey="task_count_external"
                      stroke="#1aab3b"
                      name="External"
                    />
                    <Line
                      type="monotone"
                      dataKey="task_count_internal"
                      stroke="#1a35ab"
                      name="Internal"
                    />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(date) =>
                        new Date(date).toLocaleString("en-US", {
                          month: "short",
                        })
                      }
                    />
                    <YAxis />
                    <Tooltip />
                  </LineChart>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent>
              <ChartTitle title="Hours Worked Per Month" />
              {isLoading ? (
                <LoadingBox />
              ) : (
                <Box display="flex" justifyContent="center">
                  <LineChart
                    width={650}
                    height={300}
                    data={chartData["hours_worked_monthly"]}
                  >
                    {/* <Line
                      type="monotone"
                      dataKey="hours_worked"
                      stroke="#8884d8"
                      name="Total Hours"
                    /> */}
                    <Line
                      type="monotone"
                      dataKey="hours_worked_external"
                      stroke="#1aab3b"
                      name="Hours (External)"
                    />
                    <Line
                      type="monotone"
                      dataKey="hours_worked_internal"
                      stroke="#1a35ab"
                      name="Hours (Internal)"
                    />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(date) =>
                        new Date(date).toLocaleString("en-US", {
                          month: "short",
                        })
                      }
                    />
                    <YAxis />
                    <Tooltip />
                  </LineChart>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Third Row */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
        Current Week: {currentWeek()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <ChartTitle title="Tasks by Category" />
              {isLoading ? (
                <LoadingBox />
              ) : (
                <Box display="flex" justifyContent="center">
                  <BarChart
                    width={650}
                    height={600}
                    data={chartData["tasks_by_category"]}
                    layout="vertical"
                    barCategoryGap={2}
                    barGap={2}
                  >
                    <Bar dataKey="value" fill="#413ea0" barSize={30} />
                    <XAxis type="number" />
                    <YAxis
                      dataKey="name"
                      type="category"
                      tick={{ fontSize: 14, wordBreak: "break-word" }}
                      width={200}
                    />
                    <Tooltip cursor={false} />
                  </BarChart>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent>
              <ChartTitle title="Task Status" />
              {isLoading ? (
                <LoadingBox />
              ) : (
                <Box display="flex" justifyContent="center">
                  <PieChart width={650} height={600}>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={chartData["tasks_by_status"]}
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      fill="#8884d8"
                      label={({ name, value }) => `${name}: ${value}`}
                      labelPosition="inside"
                      labelLine={false}
                    >
                      {chartData["tasks_by_status"].map((_entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={PIE_COLORS[index % PIE_COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip offset={0} />
                  </PieChart>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirmDashboard;
