import React from "react";
import { makeStyles } from "@mui/styles";

import CopyTaskLink from "../../../../../../../shared/components/CopyTaskLink";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "0",
    lineHeight: "30px !important",
  },
}));

export default ({ task }) => {
  const classes = useStyles();

  return (
    <h2 className={classes.title}>
      #{task.id}&nbsp;
      {task.name}
      <CopyTaskLink taskId={task.id} />
    </h2>
  );
};
