import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Avatar, Autocomplete } from "@mui/material";

const useStyles = makeStyles({
  container: {
    width: (props) => props.width,
  },
  input: {
    width: "100%",
    marginRight: "10px",
  },
  avatar_wrapper: {
    "& > div": {
      width: "28px",
      height: "28px",
      marginRight: "10px",
    },
  },
});

const option_style = {
  display: "flex",
  fontSize: "11pt",
  padding: "10px",
  cursor: "pointer",
  borderBottom: "1px solid #555",
  marginBottom: "0",
};

export default ({
  name,
  title,
  default_value = null,
  options,
  onChange,
  width,
  presetValue,
}) => {
  const classes = useStyles({ width });
  const [value, setValue] = useState(default_value);

  useEffect(() => {
    if (presetValue) {
      setValue(presetValue);
    }
  }, [presetValue]);

  const onChangeLocal = (e, target_value) => {
    setValue(target_value);
    onChange(sendBackData(target_value));
  };

  const sendBackData = (target_value) => {
    let data = {};
    data[name] = target_value?.value ?? "";

    return data;
  };

  const OptionComponent = ({ option, props }) => {
    return (
      <div style={option_style} {...props}>
        <span className={classes.avatar_wrapper}>
          <Avatar src={option.profile_pic}>{option.code}</Avatar>
        </span>
        {option.label}
      </div>
    );
  };

  const InputComponent = ({ params, title }) => (
    <TextField {...params} label={title} variant="outlined" />
  );

  return (
    <div className={classes.container}>
      <Autocomplete
        multiple={false}
        size="small"
        value={value}
        options={options}
        limitTags={1}
        className={classes.input}
        onChange={onChangeLocal}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <OptionComponent {...{ option, props }} />
        )}
        renderInput={(params) => <InputComponent {...{ params, title }} />}
        sx={{ minWidth: "200px" }}
      />
    </div>
  );
};
