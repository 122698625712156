import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Autocomplete, Box, Chip, Modal, TextField } from "@mui/material";
import FilterDropdown from "../FilterDropdown";
import FilterFrequency from "../FilterFrequency";
import Toggle from "../add-template/Toggle";
import FilterButtons from "../FilterButtons";
import EstimatedHours from "../EstimatedHours";
import InputPeople from "../../../../../board/components/_basic_filters/InputPeople";
import { ACCOUNTING_PROJECT_TASK } from "../../../../../../const/api_paths";
import { doAuthenticatedPut } from "../../../../../../actions/_methods";
import { useGlobal } from "../../../../../context/global";
import CustomTimePicker from "../CustomTimePicker";
import TaskFor from "../../../../../board/components/shared/task_for/Main";

import TaskTags from "../Tags";

const useStyles = makeStyles({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    backgroundColor: "#f8f8ff",
    padding: 20,
    borderRadius: "5px",
    boxShadow: "5px 5px 5px #00000029",
    height: "100%",
    overflowY: "auto",
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 20,
  },
  wrapper: {
    gap: 15,
    display: "flex",
    paddingBottom: 20,
    flexDirection: "column",
    borderBottom: "1px solid #00000029",
  },
  container: {
    gap: 10,
    display: "flex",
    flexDirection: "column",
    border: "1px solid #00000029",
  },
});

const EditTemplate = ({
  open,
  firm,
  task,
  users,
  reloadData,
  handleClose,
  topLevelCategories,
  subLevelCategories,
}) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const [scope, setScope] = useState("firm-wide");
  const [accountIDs, setAccountIDs] = useState([]);
  const [locationIDs, setLocationIDs] = useState([]);
  const [workAsOne, setWorkAsOne] = useState(false);
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [subCategory, setSubCategory] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [earliestStart, setEarliestStart] = useState(null);
  const [estimatedHours, setEstimatedHours] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);
  const [showFrequencies, setShowFrequencies] = useState(false);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [selectedFrequencies, setSelectedFrequencies] = useState([]);
  const disableAddTask = parentCategory === null || subCategory === null;

  const arrayToString = (ids) => {
    if (ids.length === 0) return "";

    return ids.join(",");
  };

  const getIds = (items) => {
    if (!items) return [];

    return items.map((item) => item.id);
  };

  const getSelectedFrequencies = (frequencies) => {
    if (!frequencies) return [];

    return frequencies.map((frequency) => frequency.name);
  };

  const params = {
    firm_id: firm?.id,
    description: description,
    tags: tags,
    selected_week: selectedWeek,
    earliest_start: earliestStart,
    estimated_hours: estimatedHours,
    scope: scope,
    account: arrayToString(accountIDs),
    location: arrayToString(locationIDs),
    work_as_one: workAsOne,
    assigned_users: arrayToString(assignedUsers),
    frequencies: arrayToString(selectedFrequencies),
    task_category_id: subCategory && subCategory.id,
  };

  const endpoint = () => {
    return ACCOUNTING_PROJECT_TASK.replace(":id", task?.id);
  };

  const onEditTask = () => {
    doAuthenticatedPut(endpoint(), params, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  };

  const onSuccess = (res) => {
    const message = "Successfully edited task!";

    prompt(message);
    reloadData();
    handleClose();
  };

  const onError = (res) => {
    let errorMessage = "Unable to create the Task Template:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" ");
    }
    prompt(errorMessage, { variant: "error" });
  };

  const handleChange = (event, type) => {
    const value = event.target.value === "" ? null : event.target.value;

    switch (type) {
      case "parentCategory":
        setParentCategory(value);

        if (value) {
          setSubCategory(null);
          setSubCategoryOptions(value.children);
        }
        break;

      case "subCategory":
        setSubCategory(value);
        if (value) setParentCategory(value.parent);
        break;

      case "description":
        setDescription(value);
        break;

      case "earliestStart":
        setEarliestStart(value);
        break;

      default:
        break;
    }
  };

  const handleTaskForChange = (value) => {
    setScope(value.scope);
    setWorkAsOne(value.work_as_one);

    if (value.account && value.account.id) {
      setAccountIDs([value.account.id]);
    } else {
      setAccountIDs([]);
    }

    if (value.locations && value.locations) {
      setLocationIDs(value.locations.map((location) => location.id));
    } else {
      setLocationIDs([]);
    }
  };

  const onChangeUsers = (value) => {
    const ids = value.user_ids;

    setAssignedUsers(ids);
  };

  const onChangeTags = (_event, value) => {
    setTags(value);
  };

  const onCancel = () => {
    initializeValues();
    handleClose();
  };

  const onSelectNone = () => {
    setParentCategory(null);
    setSubCategory(null);
    setSubCategoryOptions(subLevelCategories);
  };

  const initializeValues = () => {
    setScope(task?.task_for);
    setDescription(task?.description);
    setSubCategory(task?.sub_category);
    setTags(task?.tags);
    // setAccountIDs(getIds(task?.accounts));
    // setLocationIDs(getIds(task?.locations));
    setParentCategory(task?.parent_category);
    setEstimatedHours(task?.estimated_hours);
    setShowFrequencies(task?.frequency !== null);
    setEarliestStart(task?.earliest_time_to_start);
    setAssignedUsers(getIds(task?.assigned_users));
    setSubCategoryOptions(task?.parent_category.children);
    setSelectedFrequencies(getSelectedFrequencies(task?.frequency));
  };

  useEffect(() => {
    if (task) {
      initializeValues();
    }
  }, [task]);

  return (
    <Modal open={open} onClose={onCancel}>
      <Box className={classes.box}>
        <div className={classes.header}>Edit Task</div>
        <div className={classes.wrapper}>
          <FilterDropdown
            required={true}
            width="100%"
            label="Parent Category"
            value={parentCategory}
            onSelectNone={onSelectNone}
            onChange={(e) => handleChange(e, "parentCategory")}
            options={topLevelCategories ? topLevelCategories : []}
            noOptionsMessage="No Parent Categories"
          />

          <FilterDropdown
            required={true}
            width="100%"
            label="Sub Category"
            value={subCategory}
            onChange={(e) => handleChange(e, "subCategory")}
            options={subCategoryOptions ? subCategoryOptions : []}
            noOptionsMessage="No Sub Categories"
          />

          <TaskTags
            tags={tags}
            onChange={onChangeTags}
            taskCategoryName={subCategory?.name}
          />

          <TextField
            multiline
            size="small"
            variant="outlined"
            label="Description"
            rows={4}
            name="Description"
            value={description}
            onChange={(e) => handleChange(e, "description")}
          />

          <TaskFor
            firm_id={firm?.id}
            task={task}
            onChange={handleTaskForChange}
          />

          <div className={classes.container}>
            <Toggle label="Frequencies" setShow={setShowFrequencies} />
            {showFrequencies && (
              <FilterFrequency
                task={task}
                width="100%"
                setSelectedWeek={setSelectedWeek}
                setSelectedFrequencies={setSelectedFrequencies}
              />
            )}
          </div>

          <InputPeople
            limit={3}
            width="100%"
            name="user_ids"
            title="Assigned Users"
            onChange={onChangeUsers}
            options={users ? users : []}
            default_value={task?.assigned_users}
          />

          <CustomTimePicker
            width="100%"
            label="Earliest Time to Start"
            value={earliestStart}
            onChange={setEarliestStart}
          />

          <EstimatedHours
            width="100%"
            label="Estimated Hours"
            estimate={estimatedHours}
            setInput={setEstimatedHours}
          />
        </div>
        <FilterButtons
          labelCancel="Cancel"
          labelSave="Update Task"
          onClickSave={onEditTask}
          onClickCancel={onCancel}
          disableSave={disableAddTask}
        />
      </Box>
    </Modal>
  );
};

export default EditTemplate;
