import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import AddAccount from "./_add_account";
import ActionAccount from "./_actions_account";
import ReportsAccount from "./_reports_account";
import Locations from "./_locations";
import { useFirmsCtx } from "../context";
import { SpinnerWithBackdrop } from "../../../../shared/components/utils/_spinner";

const useStyles = makeStyles((theme) => ({
  cell: {
    verticalAlign: "top",
  },
  addCell: {
    borderBottom: "none",
  },
}));

export default ({ firm }) => {
  const classes = useStyles();
  const { isLoading } = useFirmsCtx();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.addCell}>
              <AddAccount firm={firm} />
            </TableCell>
          </TableRow>
          {firm.accounts.map((account) => (
            <TableRow key={`account_${account.id}`}>
              <TableCell
                component="th"
                scope="row"
                className={classes.cell}
                style={{ width: "300px", verticalAlign: "top" }}
              >
                <Stack direction="row" spacing={2}>
                  <Avatar alt={account.name} src={account.avatar} />
                  <b>{account.name}</b>
                </Stack>
                <ActionAccount account={account} />
                <ReportsAccount account={account} />
              </TableCell>
              <TableCell>
                <Locations account={account} locations={account.locations} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isLoading === "secondary" && <SpinnerWithBackdrop />}
    </TableContainer>
  );
};
