import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = makeStyles({
  copyButton: {
    marginLeft: "10px",
  },
  copyIcon: {
    fontSize: "16px", // Adjust the icon size
  },
});

export default ({ taskId }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/app/task/${taskId}`
    );
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Tooltip title={copied ? "Copied!" : "Copy Link"} placement="top">
      <IconButton
        className={classes.copyButton}
        onClick={copyToClipboard}
        color={copied ? "success" : "inherit"}
        size="small"
      >
        <ContentCopyIcon className={classes.copyIcon} color="primary" />
      </IconButton>
    </Tooltip>
  );
};
