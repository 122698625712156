export const STATUS_OPTIONS = [
  { value: 'unstarted', label: 'Unstarted' },
  { value: 'on_going', label: 'On-Going' },
  { value: 'paused', label: 'Paused' },
  { value: 'stucked', label: 'Stucked' },
  { value: 'completed', label: 'Completed' },
];

export const BILLABLE_OPTIONS = [
  { value: 'true', label: 'Billable' },
  { value: 'false', label: 'Non-billable' },
];

export const WEEK_OPTIONS = [
  { value: '1_3', label: 'First/Third Week of the month' },
  { value: '2_4', label: 'Second/Fourth Week of the month' },
];

export const TEAM_OPTIONS = [
  { value: 'Asia/Manila', label: 'Manila' },
  { value: 'America/Bogota', label: 'Bogota' },
];

export const TAG_OPTIONS = [
  { value: 'Data', label: 'Data' },
  { value: 'OEO', label: 'OEO' },
  { value: 'AP - OEO', label: 'AP - OEO' },
  { value: 'Business Support', label: 'Business Support' },
  { value: 'PM', label: 'PM' },
  { value: 'Tech', label: 'Tech' },
  { value: 'Others', label: 'Others' },
];

export const SHIFT_OPTIONS = [
  { value: "shift_1", label: "Shift 1" },
  { value: "shift_2", label: "Shift 2" },
  { value: "shift_3", label: "Shift 3" },
];

export const TASK_REQUEST_STATUS_OPTIONS = [
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
  { value: "declined", label: "Declined" },
];

export const CATEGORY_TYPE_OPTIONS = [
  { value: "internal", label: "Internal" },
  { value: "external", label: "External" },
];

export const DEFAULT_TAGS = {
  "Internal Business Management": [
    "#teamhuddle",
    "#meeting",
    "#coaching",
    "#clienttask",
    "#taskaudit",
    "#companyevent",
    "#systemissue",
    "#specialproject",
  ],
  "Software Development": [
    "Feature",
    "Chores",
    "Bug Fixes",
    "Follow Up",
    "Icebox",
    "Frontend",
    "Backend",
    "Infrastructure",
  ],
}
