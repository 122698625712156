import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Modal, TextField, Button } from '@mui/material';
import { useTaskCategory } from '../../context/task_categories';
import { doAuthenticatedPost } from '../../../actions/_methods';
import { TASK_CATEGORIES } from '../../../const/api_paths';
import { useGlobal } from '../../context/global';
import { Spinner } from '../../../shared/components/utils/_spinner';
import { CATEGORY_TYPE_OPTIONS } from "../../board/const/ref";
import InputSelect from "../../board/components/_basic_filters/InputSelect";

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    backgroundColor: '#f8f8ff',
    borderRadius: '5px',
  },
  save: {
    backgroundColor: '#3059fb',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#2b50e2',
    },
  },
  cancel: {
    backgroundColor: '#f3f4f6',
    color: '#616775',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#dbdcdd',
    },
  },
  header: {
    display: 'flex',
    fontWeight: 500,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 24,
    paddingRight: 24,
    color: '#FFFFFF',
    backgroundColor: '#333232',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
  },
  title: {
    marginTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
    marginTop: 20,
    paddingTop: 20,
    borderTop: '1px solid #00000029',
  },
  error: {
    color: '#c60000',
    fontSize: 12,
    paddingTop: 4,
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const AddTaskCategory = ({ open, handleClose, applyExpandedState }) => {
  const classes = useStyles();
  const { prompt } = useGlobal();
  const { currentNode, setTaskCategories } = useTaskCategory();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categoryType, setCategoryType] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const header = `Add ${currentNode ? 'Sub' : ''} Task Category`;
  const title = currentNode ? currentNode.title : '';
  const nameIsEmpty = name === '';

  const onNameChange = (event) => {
    if (errors) setErrors(null);
    setName(event.target.value);
  };

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const onCategoryTypeChange = (data) => {
    setCategoryType(data.category_type);
  };

  const requiredParams = {
    task_category: {
      name: name,
      description: description,
      category_type: categoryType,
      ...(currentNode ? { parent_id: currentNode.id } : {}),
    },
  };

  const onAddSubCategory = () => {
    setLoading(true);
    doAuthenticatedPost(TASK_CATEGORIES, requiredParams, {
      success: onAddSuccess,
      general: onAddSuccess,
      error: onError,
    });
  };

  const onError = (res) => {
    const message = res.errors.name;
    setErrors({ name: message });

    setLoading(false);
  };

  const onAddSuccess = (res) => {
    const message = `Successfully created ${name}`;
    prompt(message);

    setTaskCategories(res.map(applyExpandedState));
    setLoading(false);
    onClose();
  };

  const onClose = () => {
    setName('');
    setDescription('');
    setCategoryType('');
    setErrors(null);
    handleClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.box}>
        <div className={classes.header}>{header}</div>
        <div className={classes.title}>{title}</div>
        {loading ? (
          <Spinner height={200} />
        ) : (
          <div className={classes.container}>
            <div className={classes.textFieldContainer}>
              <div className={classes.wrapper}>
                <TextField
                  size="small"
                  variant="outlined"
                  label="Name"
                  name="Name"
                  value={name}
                  onChange={onNameChange}
                />
                {errors?.name && (
                  <span className={classes.error}>{errors.name}</span>
                )}
              </div>
              <TextField
                multiline
                size="small"
                variant="outlined"
                label="Description"
                rows={4}
                name="Description"
                value={description}
                onChange={onDescriptionChange}
              />
              <InputSelect
                width={230}
                name="category_type"
                title="Type"
                options={CATEGORY_TYPE_OPTIONS}
                onChange={onCategoryTypeChange}
                default_value={categoryType}
                presetValue={categoryType}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                className={classes.cancel}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                disabled={nameIsEmpty}
                variant="contained"
                className={classes.save}
                onClick={onAddSubCategory}
              >
                Add
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default AddTaskCategory;
