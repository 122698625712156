import React from "react";
import { Chip, TextField, Autocomplete } from "@mui/material";

import { DEFAULT_TAGS } from "../../../../board/const/ref";

export default ({ tags, onChange, taskCategoryName }) => {
  const defaultTags = DEFAULT_TAGS[taskCategoryName] || [];

  return (
    <Autocomplete
      multiple
      freeSolo
      size="small"
      value={tags}
      options={defaultTags}
      onChange={onChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="outlined" />
      )}
    />
  );
};
